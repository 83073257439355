import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '@app/core/services/auth/auth.service';
import { ModalComponent } from '@core/components/modal/modal.component';
import { ErrorResponse, GenericListResponse } from '@core/models';
import { HelperService } from '@core/services/helper.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  token: string | null = null;
  msgDialogFlag = false;

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private router: Router,
    private modal: NgbModal,
    private notificationService: NotificationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.apimKey !== '') {
      if (this.allAPIMUrls(request)) {
        if (environment.payload_encrypt === 'true') {
          request = request.clone({
            body: this.helperService.aesEncode(JSON.stringify(request.body))
          });
          request = request.clone({
            responseType: 'text'
          });

          if (!(request.method === 'GET' || request.method === 'DELETE')) {
            const requestUrl = new URL(request.url);

            const searchParams = requestUrl.searchParams;
            searchParams.set('payload_encrypt', environment.payload_encrypt);
            requestUrl.search = searchParams.toString();

            request = request.clone({
              url: requestUrl.toString()
            });
          }
        }

        if (request.method === 'GET' || request.method === 'DELETE') {
          const requestUrl = new URL(request.url);

          if (!request.url.includes('payload_encrypt')) {
            const searchParams = requestUrl.searchParams;
            searchParams.set('payload_encrypt', environment.payload_encrypt);
            requestUrl.search = searchParams.toString();
          }

          request = request.clone({
            url: requestUrl.toString()
          });
        }
      }
    }

    if (request.url.includes(environment.api + '/refresh')) {
      this.token = sessionStorage.getItem('refreshtoken');
    } else {
      this.token = sessionStorage.getItem('auth');
    }

    if (request.url.includes(environment.api)) {
      // for APIM call
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.token)
      });
      request = request.clone({
        withCredentials: true
      });
    } else if (request.url.includes(environment.authBaseURL)) {
      // for Auth APIs call
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.token)
      });
    } else if (request.url.includes(environment.rules_json_url)) {
      // dont add header
    } else {
      // for local development environment Harmony usage
      request = request.clone({
        headers: request.headers.set('Authorization', 'Basic ' + btoa('payvantage_testuser' + ':' + 'UoNaqz18Hqwnf9tXmvmdC7N27EIa4gKa'))
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }

    if (environment.apimKey !== '') {
      // for APIM call, pass subscription key
      request = request.clone({
        headers: request.headers.set('Ocp-Apim-Subscription-Key', environment.apimKey)
      });
    }

    request = request.clone({
      headers: request.headers
        .set('company-brand', environment.company_brand)
        .set('provider-id', sessionStorage.getItem('provider') || '')
        .set('Cache-Control', 'no-cache')
        .set('Accept', 'application/json')
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        // console.log('event--->>>', event);
        if (event instanceof HttpResponse) {
          if (environment.apimKey !== '') {
            if (request.url.includes('address-lookup')) {
              event = event.clone({
                headers: event.headers,
                body: JSON.parse(event.body)
              });
            } else if (this.allAPIMUrls(request)) {
              if (environment.payload_encrypt === 'true') {
                event = event.clone({
                  headers: event.headers,
                  // TODO temporarily disable response decode Reason cannot get the cookie from header after calling AZF
                  // body: this.helperService.aesDecode(event.body),
                  body: JSON.parse(event.body)
                });
              }
            } else {
              event = event.clone({
                headers: event.headers,
                body: event.body
              });
            }
          } else {
            event = event.clone({
              headers: event.headers,
              body: event.body
            });
          }
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorEMsg = error.error;

        if (environment.apimKey !== '') {
          if (this.allAPIMUrls(request)) {
            if (environment.payload_encrypt === 'true') {
              console.log(errorEMsg);
              errorEMsg = JSON.parse(errorEMsg);
            }
          }
        }

        if (error.status === 401) {
          // authentication error
          // logout
          if (!this.msgDialogFlag) {
            const msgDialog = this.modal.open(ModalComponent, { size: 'sm', centered: true });

            msgDialog.componentInstance.data = {
              title: 'Authentication Error',
              closeBtn: true,
              content: 'Session timed out, please log in again',
              buttons: [
                {
                  text: 'Log in',
                  class: 'btn-primary',
                  value: 'ok'
                }
              ]
            };

            msgDialog.closed.subscribe((result) => {
              if (result === 'ok') {
                if (!this.authService.checkTokenExpiredForRefresh()) {
                  sessionStorage.clear();
                  this.router.navigate(['login']);
                } else {
                  if (sessionStorage.getItem('auth') != null) {
                    this.authService.logout();
                  } else {
                    sessionStorage.clear();
                    this.router.navigate(['login']);
                  }
                }
              }
            });

            this.msgDialogFlag = true;
          }
        } else if (error.status === 403) {
          // permission & routing
          const dialogButtons = [
            {
              title: 'OK',
              class: 'secondary small'
            }
          ];

          const msgDialog = this.modal.open(ModalComponent);
          msgDialog.componentInstance.data = {
            title: 'Access Error',
            content: 'You are required to enter your PIN to unlock.',
            buttons: dialogButtons
          };
          msgDialog.componentInstance.disableClose = true;

          msgDialog.closed.subscribe((result: any) => {
            if (result === 'OK') {
              if (sessionStorage.getItem('auth') != null) {
                this.router.navigate(['/login/enter-pin']);
              }
            }
          });
        } else if (error.status === 422) {
          const errors = typeof error.error === 'string' ? JSON.parse(error.error).errors : error.error.errors;
          const errObj: { message: string; title?: string; code?: string; isRedirect?: boolean } = errors[0];

          if (errObj.isRedirect) {
            this.modal.dismissAll();
            sessionStorage.setItem('errorRedirectionMsg', errObj.message);
            this.router.navigate([`payment-sms-journey/error`]);
          }

          if (errObj.code && errObj.code === 'OTP_MAX_ATTEMPT' && !errObj.isRedirect) {
            this.openErrorModal(errObj.title || 'Error', errObj.message);
          }

          this.notificationService.error(this.parseAPIErrorMessage(errorEMsg), errObj.title || 'Error');
        } else if (error.status === 500) {
          // internal server error
          this.notificationService.error(this.parseAPIErrorMessage(errorEMsg) || error?.error.message, 'Error');
        } else {
          // Others HTTP Code
          this.notificationService.error('The Portal has encountered an error. Please log back in to continue.', 'Application Error');
        }
        return throwError(error);
      })
    );
  }

  public parseAPIErrorMessage(data: GenericListResponse<any, ErrorResponse>) {
    try {
      let errMsg = '';
      errMsg = errMsg + '';

      if (Object.prototype.hasOwnProperty.call(data, 'errors')) {
        if (data.errors) {
          const phpApiData = data.errors;
          for (const apiData of phpApiData) {
            errMsg = errMsg + '' + '<p class="mb-1">' + apiData.message + '</p>';
          }
        }
      }

      errMsg = errMsg + '';

      return errMsg;
    } catch (error) {
      // @ts-ignore
      return error.message;
    }
  }

  public allAPIMUrls(request: any): boolean {
    const apimUrls = [environment.api, environment.authBaseURL, environment.basiq.apiBaseUrl];
    return apimUrls.some((url) => request.url.includes(url));
  }

  private openErrorModal(title: string, message: string): void {
    const modalRef = this.modal.open(ModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      uilIconClass: 'uil-exclamation-triangle',
      title,
      content: message,
      buttons: [
        {
          text: 'Acknowledge',
          class: 'btn-primary w-230 ml-1',
          value: 'true'
        }
      ]
    };

    modalRef.closed.pipe(take(1)).subscribe(() => {
      const pendingPlanRouteUrl = 'payment-plan-summary/pending';

      if (this.router.url !== pendingPlanRouteUrl) {
        this.router.navigate([pendingPlanRouteUrl]);
      }
    });
  }
}
